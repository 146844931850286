<template>
  <v-row wrap no-gutters>
    <v-col cols="12" class="pt-1">
      <v-text-field
        v-model="step.name"
        outlined
        dense
        :label="$lang.labels.name"
        :readonly="!canEdit"
        data-cy="try-name"
        @input="handleChange('name', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TryOrFinallyStep',
  props: {
    step: {
      type: Object,
      default: () => {
        return null
      }
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange(key, val) {
      this[key] = val
    }
  }
}
</script>
